import React, { useReducer, useContext, useEffect } from 'react'
/** @jsx jsx */
import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache } from "aws-amplify"
import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex } from "../graphql/shotassetQueries"

import Helmet from 'react-helmet'

import {loadStripe} from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';

import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import ProductCard from '../@geocine/gatsby-theme-organization/components/ProductCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}


var stripePromise, checkoutSessionId, uGroups

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const {error, paymentMethod} = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//     });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement />
//       <button type="submit" disabled={!stripe}>
//         Pay
//       </button>
//     </form>
//   );
// };


var setupElements = async function() {
  stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
  // console.log('stripePromise:', stripePromise)
  // const pubkey = await API.get('shotwireAPI', '/stripe-pub-key', 
  // // {
  //                       // 'queryStringParameters': {
  //                       //   // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
  //                       //   // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
  //                       //   // 'setName': appsContext.state.videoPlayer.assetObj.name,
  //                       //   // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
  //                       //   'jobAction': 'createProxy',
  //                       //   's3srcUrl' : srcObj.srcUrl,
  //                       //   'assetName' : srcObj.name,
  //                       //   'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
  //                       // }
  //                     // }
  //                     );
  //       console.log('pubkey:', pubkey)
  //       return pubkey

  // fetch("/public-key", {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // })
  //   .then(function(result) {
  //     return result.json();
  //   })
  //   .then(function(data) {
  //     stripe = Stripe(data.publicKey);
  //   });
};




// var createCheckoutSession = function(isBuyingSticker) {

//   let apiName = 'shotwireAPI'; // replace this with your api name.
//   let path = '/create-checkout-session'; //replace this with the path you have configured on your API
//   let myInit = {
//       body: JSON.stringify({ isBuyingSticker }),//{}, // replace this with attributes you need
//       headers: {"Content-Type": "application/json"} // OPTIONAL
//   }

//   API.post(apiName, path, myInit).then(response => {
//       // Add your code here
//       console.log('response:',response)
//       return response.json();
//   }).then(data => {
//       // Add your code here
//       console.log('data:',data)
//       checkoutSessionId = data.checkoutSessionId;
//   }).catch(error => {
//       console.log(error.response)
//   });

//         fetch("/create-checkout-session", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json"
//             },
//             body: JSON.stringify({ isBuyingSticker })
//           })
//             .then(function(result) {
//             return result.json();
//           })
//           .then(function(data) {
//             checkoutSessionId = data.checkoutSessionId;
//           });
//   };

const CloudProductPage = () => {
  // let stripe = null 
  let session = null

  const appsContext = React.useContext(AppsContext)

  // const stripeInit = async () => {
  //   stripe = await loadStripe('pk_test_8iiUqRzMdP7ipyKMn1wHDhyF');
  //   const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
  //   console.log('stripe:', stripe)
  //   appsContext.dispatch({ type: "STRIPE", stripe })
    
  
  // }

  // const stripeSession = async (planId) => {
  //   console.log('stripePromise...', stripePromise)
  //   session = await stripePromise.checkout.sessions.create({
  //     // customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: planId,
  //       }],
  //       trial_from_plan: true,
  //     },
  //     success_url: 'https://video2020.shotwire.com/buysccss',
  //     cancel_url: 'https://video2020.shotwire.com/buycncl',
  //   });

  //   return session
    
  // }

  // (async () => {
  //   const session = await stripe.checkout.sessions.create({
  //     customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: 'plan_123',
  //       }],
  //     },
  //     success_url: 'https://example.com/success',
  //     cancel_url: 'https://example.com/cancel',
  //   });
  // })();

  const handleBuy = (e, planId) => {
    if (e){ 
        e.preventDefault();
      
        // console.log('stripe checkout...')
        // const sessId = stripeSession(planId)
        // console.log('stripe sessId', sessId)
        appsContext.dispatch({type:"CHECKOUT",isCheckoutLoading:true})

        Analytics.record({
            name: 'checkout', 
            // Attribute values must be strings
            attributes: { product: "cloud video", plan: planId }
        });

        createCheckoutSession(planId).then(sessId => {
            // Add your code here
            // console.log('sessId:',sessId)
            // checkoutSessionId = sessId;
            appsContext.dispatch({type:"CHECKOUT", isCheckoutLoading:false})


        })
        
        // console.log('checkoutSessionId', checkoutSessionId)
        // Initiate payment
        // stripePromise
        //   .redirectToCheckout({
        //     sessionId: checkoutSessionId
        //   })
        //   .then(function(result) {
        //     console.log('stripe result:', result)
        //     // console.log("error");
        //     // If `redirectToCheckout` fails due to a browser or network
        //     // error, display the localized error message to your customer
        //     // using `result.error.message`.
        //   })
        //   .catch(function(err) {
        //     console.log('stripe err:',err);
        //   });

        // stripePromise.redirectToCheckout({
        //     // Make the id field from the Checkout Session creation API response
        //     // available to this file, so you can provide it as parameter here
        //     // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        //     sessionId: sessId
        //   }).then(function (result) {
        //     console.log('stripe result:', result)
        //     // If `redirectToCheckout` fails due to a browser or network
        //     // error, display the localized error message to your customer
        //     // using `result.error.message`.
        //   });

      

    }
  }
  
  async function createCheckoutSession(planId) { 
    let apiName = 'shotwireAPI'; // replace this with your api name.
    let path = '/create-checkout-session'; //replace this with the path you have configured on your API
    let myInit = {
                    body: {
                      planId: planId
                    }, // replace this with attributes you need
                    headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
                 }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            if(result.error){
              console.log('error:', result.error.message)
              appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Purchase Error", body: result.error.message }})
            }
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}

    useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up stripe element...')
          setupElements()

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])

	useEffect(() => {
		      try{
            let isMounted = true
            if(isMounted === true) setupElements();
           
             if(appsContext.state.user){
              // const  userInfo  = appsContext.state.user
              // console.log('userInfo: ', userInfo)
              // const uRoles = userInfo.attributes[`custom:roles`].split(',')
              //user roles checks
              // if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) appsContext.dispatch({ type: "ISADMIN", isAdmin:true })  // checks for one or more permissions to allow access to specific components
             }

          
          // createCheckoutSession();
          // stripeInit()
         // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              // getCachedAssetData()
              if( appsContext.state.isAdmin ){
                // console.log('live data...')
                // getAssetSearchData()
              }
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };
          } catch(error) {
            // console.log('useEffect error:',error)
          }
		}, [ ]);




  //cost from $.01@SD - $.06@4k / min of converted footage depending on resolution and total fps processed.
 

    const cloudProducts = [
        // {
        //   title: "Free",
        //   titleBgColor: "rgba(51, 75, 135, .35)",
        //   subtitle: "No Credit Card needed",
        //   price: "Free",
        //   planId: "plan_HC5zQvCcu53ZtM", //"plan_GksirIDuQ30TXw",
        //   planIdAnnual: "plan_HC5zE926WewpA0", //"plan_GktBYQnFayBrVP",
        //   public: true,
        //   description: "Single User;2GB Storage;HD, SD, Outputs*;AVC codec, single-pass encoding"
          
        // },
        {
          title: "Basic",
          titleBgColor: "rgba(51, 75, 135, .55)",
          subtitle: "",
          price: "$24/mo",
          planId: "plan_HC5zQvCcu53ZtM", //"plan_GksirIDuQ30TXw",
          planIdAnnual: "plan_HC5zE926WewpA0", //"plan_GktBYQnFayBrVP",
          public: true,
          description: "Single User;1GB/week, 50GB Storage;Your Branded Player;Private Share Links;Marketplace Revenue Option, Earn License Fees with your videos;HD, SD, 4K Video Outputs*;AVC codec, single-pass encoding"
          
        },
        {
          title: "Standard",
          titleBgColor: "rgba(51, 75, 135, .7)",
          subtitle: "",
          price: "$48/mo",
          planId: "plan_HC5zWV7CQXt91y", //"plan_GktA5KJR6EDMHV",
          planIdAnnual: "plan_HC5zmqNYRfE2Ry", //"plan_GktBYQnFayBrVP",
          public: true,
          description: "Single User;2GB/week, 100GB Storage;Your Branded Player;Private Share Links;Marketplace Revenue Option, Earn License Fees with your videos;HD, SD, 4K Video Outputs*;AVC codec, single-pass encoding"
          
        },
        {
          title: "Pro",
          titleBgColor: "rgba(51, 75, 135, .85)",
          subtitle: "",
          price: "$148/mo",
          planId: "plan_HHKW7ol7SWCgS9",   //"plan_GktAanV1vB4DyD",
          planIdAnnual: "plan_HHKWXwJv9VWSxW", //"plan_GktBYQnFayBrVP",
          public: true,
          description: "3 Team Member Accounts;300GB Yearly Storage + Unlimited Backup to your private AWS S3 Storage;Your Branded Video WEB PAGE;HD, SD, 4K, 8K Video Outputs + AWS PRO Configurations options;+AI Facial & Content Recognition Meta Tagging options;Video Tracking Analytics"
          
        },
        {
          title: "Studio/Agency Startup",
          titleBgColor: "rgba(51, 75, 135, 1)",
          subtitle: "",
          price: "$298/mo",
          priceNotes: "+ $1,998 One-time Setup Fee",
          planId: "plan_HC5zdyEICs2iNm", //"plan_GktBYQnFayBrVP",
          planIdAnnual: "plan_HC5zH6UorVVWJ2", //"plan_GktBYQnFayBrVP",
          terms:"One-time Setup fee due upon setup completion",
          buyButton:"BUY NOW & SCHEDULE INSTALL DATE",
          public: false,
          description: "Fully Loaded Custom Cloud Deployment with Your Domain; Complete User Managment; Fully Hosted, Managed, and Customizable; Accept Credit Card Payments with Private Stripe Integration Options; Unlimited Storage on your private AWS S3 Storage account;"
         
        },
        {
          title: "Studio/Agency Pro Teams",
          titleBgColor: "rgba(51, 75, 135, 1)",
          subtitle: "",
          price: "$498/mo",
          priceNotes: "+ $2,498 One-time Setup Fee",
          planId: "plan_HC5zca98U1mRLw", //"plan_GktBYQnFayBrVP",
          planIdAnnual: "plan_HC5zOYpZY8zd2T", //"plan_GktBYQnFayBrVP",
          terms:"One-time Setup fee due upon setup completion",
          buyButton:"BUY NOW & SCHEDULE INSTALL DATE",
          public: false,
          description: "Fully Loaded Custom Cloud Deployment with Your Domain; Complete User Managment; Fully Hosted, Managed, and Customizable; Accept Credit Card Payments with Private Stripe Integration Options; Google Ad Manager Integration Support;Video Ad Integration Support;Unlimited Storage on your private AWS S3 Storage account;"
         
        }
        ]



  return(
  	
<Layout>
  <Helmet>
    <title>Shotwire Cloud Plans</title>
    <link rel="icon" href={favicon} type="image/x-icon" />
    
  </Helmet>

 <Container>
 <Row>
 <div sx={{
  paddingLeft: '10px',
  paddingTop: '20px'
 }}>
 <h1>Choose a plan.</h1>
 <h5>Cloud Video Hosting Plans</h5>
 
 </div>
 </Row>
 <Row>
 <Col sx={{
  // paddingLeft: '10px',
  // padding: '20px 0px 20px',
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '.9rem'

 }}>
 <p>
  Shotwire's Cloud Video Service is the fastest way for users and organizations to start publishing and streaming video with custom branded, and tracking enabled viewing to connected devices worldwide.
  </p>
  <p>
  We've built our robust video platform on Amazon Web Services (AWS). Choose a plan below to get started...
</p>
 </Col>
 </Row>

<Row>
        
        { 

          cloudProducts.map((product, key) => {
                      const description = product.description.split(';')
                      // console.log('description:', description)

                      return (
                          <Box sx={{ width: ['100%', '50%', '20%'], px: 2, py: 2 }} key={key}>
                            <ProductCard assetObj={product} assetId={product.assetId} name={ product.title } titleBgColor={ product.titleBgColor } href="#!" image=''>
                               {product.subtitle}
                          
                                <div style={{fontSize:'1rem', color: colorBlack80}}>
                                
                                  <ListGroup variant="flush">
                                
                                
                                {
                                  description.map((line,key) => (

                                      // <span>{line}</span>

                                      // <li role="listitem" class="">{line}</li>
                                      <ListGroup.Item key={key}>{line}</ListGroup.Item>
                                   ))                                            
                                }
                                
                                
                                  </ListGroup>
                                
                                

                                </div>
                                <div>
                                
                                <span style={{fontSize:'1.3rem', color: colorBlack80}}>
                                {
                                  // (/*appsContext.state.user!==null && */product.public!==false)?product.price:"Log In For Pricing"
                                (appsContext.state.user!==null) ? 
                                <div sx={{ color: colorBlack80}}>
                                  {product.price}
                                  <br />
                                  <span style={{fontSize:'.8rem'}}>{product.priceNotes}</span> 
                                  </div> 
                                : 
                                ( 
                                  product.public ? 
                                  <div sx={{ color: colorBlack80}}>
                                  {product.price}
                                  <br />
                                  <span style={{fontSize:'.8rem'}}>{product.priceNotes}</span> 
                                  </div>
                                  : 
                                  "Log In For Pricing & Schedule Setup" 
                                  )
                                  
                                  }
                                </span>
                                </div>
                                <div>
                                <Button 
                                  id="playButton" 
                                  // variant="info" 
                                  onClick={e => handleBuy(e, product.planId)}
                                  disabled={(appsContext.state.user!==null) ? false:!product.public}
                                  sx={{backgroundColor:"rgba(89, 130, 235, 1)"}}
                                  >   
                                  {
                                    (appsContext.state.isCheckoutLoading) 
                                    ? 
                                      (
                                        <span> Checkout <FontAwesomeIcon
                                            icon={faSpinner}
                                            size="sm"
                                            sx={{
                                              // marginRight: '0.5rem'
                                            }}
                                            spin
                                          /> 
                                        </span>
                                      )
                                      
                                      : 
                                      
                                      (appsContext.state.user!==null) 
                                      ? 
                                      (product.buyButton) ? <b>{product.buyButton}</b>:<b>BUY NOW</b> 
                                      : 
                                      ( product.public ? <b>BUY NOW</b> : <b>LOG IN TO PURCHASE & SCHEDULE SETUP</b> )
                                 
                                  }
                                 {
                                    // (appsContext.state.user!==null) ? <b>BUY NOW</b> : ( !product.public ? <b>BUY NOW</b> : <b>LOG IN TO PURCHASE</b> )
                                  } 
                                </Button>
                                <br />
                                {
                    (appsContext.state.user!==null) ? <span sx={{fontSize:'.8rem', color: colorBlack80}}>{product.terms}</span> : null
                   } 
                                </div>
                                <div>
                                <span style={{ color: 'gray' }}>2 Months Free!<br />with Annual Pay</span><br />
                                {
                                (appsContext.state.user!==null) ? <a href="#!" onClick={e => handleBuy(e, product.planIdAnnual)}>Click here for Annual Pay</a>: ( product.public ? <a href="#!" onClick={e => handleBuy(e, product.planIdAnnual)}>Click here for Annual Pay</a> : null )
                               }
                                
                                </div>
                            </ProductCard>
                          </Box>
                        )
                      }
          )
        }

        </Row>
 <Row>
 <Col sx={{
  // paddingLeft: '10px',
  // padding: '20px 0px 20px',
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '1rem'

 }}>
<b><sup>*</sup> Per AWS, Resolutions for both the Basic and Professional tiers are defined as:</b>
<p>
<b>SD</b> is an output of less than 720 resolution.
<br />
<b>HD</b> is an output of greater than or equal to 720 resolution, but less than or equal to 1080 resolution.
<br />
<b>4K</b> is an output of greater than 1080, but less than or equal to 2160 resolution.
<br />
<b>8K</b> is an output of greater than 2160, up to and including 4320 resolution.
</p>
<p>
For outputs with a landscape orientation, resolution is measured vertically.
<br />
For outputs with a portrait orientation, resolution is measured horizontally.
</p></Col>
 </Row>

      </Container>
 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default CloudProductPage